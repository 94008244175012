/*
 * @Autor: Haibo Deng
 * @Date: 2022-03-25 21:55:24
 * @Description: 入口文件
 * @LastEditTime: 2022-04-01 12:24:28
 * @LastEditors: Haibo Deng
 */
import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI, { size: "mini" });

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
