/*
 * @Autor: Haibo Deng
 * @Date: 2022-03-25 23:03:27
 * @Description:
 * @LastEditTime: 2022-04-04 16:21:30
 * @LastEditors: Haibo Deng
 */
import Vue from "vue";
import VueRouter from "vue-router";

const Layout = () => import("@pages/Layout.vue");
const Home = () => import("@pages/home/Index.vue");
const Contact = () => import("@pages/contact/Index.vue");
const Detail = () => import("@pages/detail/Index.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/detail",
        name: "detail",
        component: Detail,
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact,
      },
    ]
  },
]

const router = new VueRouter({
  routes,
});

export default router;
